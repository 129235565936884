import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'

// Components
import { Button } from 'components/Button'
import { Box } from 'components/Box'
import { Checkbox } from 'components/Checkbox'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { LocationsTable } from 'components/LocationsTable'
import { Message } from 'components/Message'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { ScheduledVCheckTable } from 'components/ScheduledVCheckTable'

// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Styles
import { Section } from './VerifyClient.styles'

// Utils, Services & Messages
import formatters from '../../utils/formatters'
import messages from './VerifyClient.messages'
import useFlashMessage from '../../hooks/FlashMessage'
import { ScheduledRandomBacTable } from '../../components/ScheduledRandomBacTable'

/**
 *
 * VerifyClient
 *
 * This container holds all components needed for the last step in
 * the Client Add flow - Verify Client
 *
 * On this step, a user can review all data they added from previous
 * steps in the Client Add flow
 *
 * The only editable information on this page is the "Notes" section
 * and the checkbox to choose whether or not to send the application
 * link to the Client
 *
 * If data is present in the ClientWizardStore, that data will be
 * displayed to the user
 *
 */
const VerifyClient = observer(
  ({
    intl,
    basicInfoInitialState,
    locationsInitialState,
    globalZonesInitialState,
    schedVChecksInitialState,
    randVChecksInitialState,
    goToStep,
    isEditing,
  }) => {
    const wizardStore = useContext(ClientWizardStoreContext)
    const history = useHistory()
    const { user, isDistributor, distributorId } = useContext(UserStoreContext)
    const { agents, getAgentsByAgency, sendInstallLinkToClient, activateClient } = useContext(
      AgencyStoreContext,
    )

    const { message: error, showMessage: showError } = useFlashMessage(null)
    const [pageLoadError, showPageLoadError] = useState(null)
    const [loading, setLoading] = useState(false)

    const [clientId, setClientId] = useState(undefined)
    const [primaryAgent, setPrimaryAgent] = useState()

    // check if initial state is being passed in, otherwise grab data from store
    // - store has default initial state of empty objects and arrays if no data
    //   has been added by the user
    const basicInfo = basicInfoInitialState || wizardStore.clientBasicInfo
    const locations = locationsInitialState || wizardStore.clientLocations
    const globalZones = globalZonesInitialState || wizardStore.clientGlobalZones
    const schedVChecks = schedVChecksInitialState || wizardStore.clientScheduledVChecks
    const randVChecks = randVChecksInitialState || wizardStore.clientRandomVChecks
    const bacScheduledChecks = wizardStore.clientScheduleBacChecks || []
    const bacRandomChecks = wizardStore.clientRandomBacChecks || []

    const isCurrentDate = moment.tz(basicInfo.monitoring_start, basicInfo.timezone)

    const clientName =
      basicInfo.first_name && basicInfo.first_name.concat(' ', basicInfo.last_name)
    const clientPhone = formatters.formatPhoneNumber(basicInfo.phone)

    const [notesState, setNotesState] = useState(null)
    const [activationLinkChecked, setActivationLinkChecked] = useState(true)

    useEffect(() => {
      async function setDefaultData() {
        // Retreive the client ID from URL parameters
        const urlParams = new URLSearchParams(window.location.search)
        const clientIdParam = urlParams.get('id')

        if (clientIdParam) {
          setClientId(clientIdParam)
        }
        // find agent name
        if (!agents) {
          await getAgentsByAgency(
            isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
            showPageLoadError,
            setLoading,
          )
        }
        agents.forEach(agent => {
          if (agent.id === basicInfo.primary_agent_id) {
            if (agent.user.first_name && agent.user.last_name) {
              setPrimaryAgent(agent.user.first_name.concat(' ', agent.user.last_name))
            } else {
              setPrimaryAgent('Not Found')
            }
          }
        })

        // get all client data in case user navigates directly to this page
        await wizardStore.getClientById(
          isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
        await wizardStore.getClientLocationsById(
          isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
        await wizardStore.getClientGlobalZonesById(
          isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
        await wizardStore.getClientSchedulesById(
          isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
        await wizardStore.getClientBacSchedulesById(
          isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
        await wizardStore.getClientBacRandomById(
          isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
      }

      setDefaultData()
    }, [])

    const back = () => {
      goToStep(2)
    }

    // function to format into long dates, ex: May 1st, 2020
    const formatDate = date => {
      if (date) {
        if (!moment(date).isValid()) {
          return null
        }
        return moment(date).format('MMMM Do, YYYY')
      }
      return 'N/A'
    }

    // convert times to local format and build randomVcheck string
    const startTime = moment(randVChecks.start, 'HH:mm').format('LT')
    const endTime = moment(randVChecks.stop, 'HH:mm').format('LT')
    const rVcheck = randVChecks.frequency
      ? `Freq. ${randVChecks.frequency}, ${startTime} - ${endTime}`
      : null

    const saveClient = async () => {
      let requestSuccess = true

      // send application link data to api
      const installLinkSent = await sendInstallLinkToClient(
        isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
        clientId,
        () => {},
        showError,
        activationLinkChecked,
      )

      if (installLinkSent === null) {
        return
      }

      // send notes to api if there are any
      if (notesState) {
        const noteObject = {
          notes: notesState,
        }
        if (!isDistributor) {
          requestSuccess = await wizardStore.postCaseNoteToClient(
            user.agencyId,
            clientId,
            basicInfo.primary_agent_id,
            noteObject,
            showError,
            setLoading,
          )
          console.log('agentid', user)
        } else {
          requestSuccess = await wizardStore.postCaseNoteClientByDistributorId(
            distributorId,
            clientId,
            basicInfo.primary_agent_id,
            noteObject,
            showError,
            setLoading,
          )
        }
      }

      // sync up the zones and activate client if it's the current date
      if (isCurrentDate) {
        await activateClient(
          isDistributor ? wizardStore.distributorClientSelectedAgencyId : user.agencyId,
          clientId,
          isEditing,
          setLoading,
          showError,
        )
      }

      if (requestSuccess) {
        // clear wizard store
        wizardStore.reset()
        history.push('/clients')
      }
    }

    if (loading || pageLoadError) {
      if (pageLoadError) {
        return (
          <Box fill justify="center" align="center">
            <Message message={pageLoadError} isError />
          </Box>
        )
      }
      return (
        <Header mini level="5" margin="medium">
          <FormattedMessage {...messages.loading} />
        </Header>
      )
    }

    return (
      <Box margin={{ bottom: 'large' }} width={{ max: '700px' }}>
        <Section>
          <Header mini level="5" margin={{ vertical: 'small' }}>
            <FormattedMessage {...messages.clientInfoHeader} />
          </Header>

          <Grid
            responsive
            rows={['auto', 'auto', 'auto']}
            columns={['small', 'small', 'auto']}
            margin="none"
            gap="medium"
            areas={[
              ['name', 'dob', 'phone'],
              ['monitoringStart', 'monitoringEnd', 'email'],
              ['primaryAgent', 'supType', 'timezone'],
            ]}
            pad={{ bottom: 'small' }}
          >
            <Box direction="column" gridArea="name">
              <Text size="xsmall">
                <FormattedMessage {...messages.nameLabel} />
              </Text>
              <Text size="small">{clientName}</Text>
            </Box>

            <Box direction="column" gridArea="dob">
              <Text size="xsmall">
                <FormattedMessage {...messages.dobLabel} />
              </Text>
              <Text size="small">{formatDate(basicInfo.date_of_birth)}</Text>
            </Box>

            <Box direction="column" gridArea="phone">
              <Text size="xsmall">
                <FormattedMessage {...messages.phoneLabel} />
              </Text>
              <Text size="small">{clientPhone || 'Not Found'}</Text>
            </Box>

            <Box direction="column" gridArea="monitoringStart">
              <Text size="xsmall">
                <FormattedMessage {...messages.monitoringStartLabel} />
              </Text>
              <Text size="small">{formatDate(moment.utc(basicInfo.monitoring_start))}</Text>
            </Box>

            <Box direction="column" gridArea="monitoringEnd">
              <Text size="xsmall">
                <FormattedMessage {...messages.monitoringEndLabel} />
              </Text>
              <Text size="small">
                {basicInfo.monitoring_end
                  ? formatDate(moment.utc(basicInfo.monitoring_end))
                  : 'Not Set'}
              </Text>
            </Box>

            <Box direction="column" gridArea="email">
              <Text size="xsmall">
                <FormattedMessage {...messages.emailLabel} />
              </Text>
              <Text size="small">{basicInfo.email || 'Not Found'}</Text>
            </Box>

            <Box direction="column" gridArea="primaryAgent">
              <Text size="xsmall">
                <FormattedMessage {...messages.agentLabel} />
              </Text>
              <Text size="small">{primaryAgent}</Text>
            </Box>

            <Box direction="column" gridArea="supType">
              <Text size="xsmall">
                <FormattedMessage {...messages.supervisionLabel} />
              </Text>
              <Text size="small">{basicInfo.supervision_type || 'Not Found'}</Text>
            </Box>

            <Box direction="column" gridArea="timezone">
              <Text size="xsmall">
                <FormattedMessage {...messages.timezoneLabel} />
              </Text>
              <Text size="small">{basicInfo.timezone}</Text>
            </Box>
          </Grid>
        </Section>

        <Section>
          <Grid
            rows={['auto', 'auto']}
            columns={['auto', 'small']}
            gap="xsmall"
            areas={[
              ['locationData', 'locationData'],
              ['globalZoneData', 'globalZoneData'],
            ]}
            pad={{ bottom: 'small' }}
          >
            <Box gridArea="locationData">
              <Header mini level="5" margin={{ vertical: 'small' }}>
                <FormattedMessage {...messages.zonesLocationHeader} />
              </Header>
              {locations.length > 0 ? (
                <LocationsTable locations={locations} />
              ) : (
                <Box background="light-3" pad="small" id="location_null_message">
                  <Text weight="bold" size="small" alignSelf="center">
                    {intl.formatMessage(messages.locationsNullMessage)}
                  </Text>
                </Box>
              )}
            </Box>

            <Box gridArea="globalZoneData">
              <Header mini level="5" margin={{ vertical: 'small' }}>
                <FormattedMessage {...messages.zonesGlobalHeader} />
              </Header>

              {globalZones.length > 0 ? (
                <LocationsTable global locations={globalZones} />
              ) : (
                <Box background="light-3" pad="small" id="global_null_message">
                  <Text weight="bold" size="small" alignSelf="center">
                    {intl.formatMessage(messages.globalZonesNullMessage)}
                  </Text>
                </Box>
              )}
            </Box>
          </Grid>
        </Section>

        <Section>
          <Header mini level="5" margin={{ vertical: 'small' }}>
            <FormattedMessage {...messages.vCheckHeader} />
          </Header>

          <Grid
            rows={['auto', 'auto', 'auto']}
            columns={['auto', 'small']}
            gap="xsmall"
            areas={[
              ['randomHeader', 'randomData'],
              ['randomNullMessage', 'randomNullMessage'],
              ['scheduledData', 'scheduledData'],
            ]}
            pad={{ bottom: 'small' }}
          >
            <Box gridArea="randomHeader" justify="center">
              <Header mini level="6" margin={{ vertical: 'xsmall' }}>
                <FormattedMessage {...messages.randomVCheckHeader} />
              </Header>
            </Box>

            {rVcheck ? (
              <Box gridArea="randomData" justify="center">
                <Text size="small" alignSelf="end">
                  {rVcheck}
                </Text>
              </Box>
            ) : (
              <Box
                background="light-3"
                pad="small"
                gridArea="randomNullMessage"
                id="random_null_message"
              >
                <Text weight="bold" size="small" alignSelf="center">
                  {intl.formatMessage(messages.randomVCheckNullMessage)}
                </Text>
              </Box>
            )}

            <Box gridArea="scheduledData">
              <Header mini level="6" margin={{ vertical: 'xsmall' }}>
                <FormattedMessage {...messages.scheduledVCheckHeader} />
              </Header>

              {schedVChecks.length > 0 ? (
                <ScheduledVCheckTable
                  vchecks={schedVChecks}
                  // bodyData={vcheckTableBodyData}
                ></ScheduledVCheckTable>
              ) : (
                <Box background="light-3" pad="small" id="scheduled_null_message">
                  <Text weight="bold" size="small" alignSelf="center">
                    {intl.formatMessage(messages.scheduledVCheckNullMessage)}
                  </Text>
                </Box>
              )}
            </Box>
          </Grid>
        </Section>
        <Section>
          <Header mini level="5" margin={{ vertical: 'small' }}>
            <FormattedMessage {...messages.bacCheckHeader} />
          </Header>

          <Grid
            rows={['auto', 'auto', 'auto']}
            columns={['auto', 'small']}
            gap="xsmall"
            areas={[
              ['randomHeader', 'randomHeader'],
              ['randomData', 'randomData'],
              ['scheduledData', 'scheduledData'],
            ]}
            pad={{ bottom: 'small' }}
          >
            <Box gridArea="randomHeader" justify="center">
              <Header mini level="6" margin={{ vertical: 'xsmall' }}>
                <FormattedMessage {...messages.randomBacCheckHeader} />
              </Header>
            </Box>
            <Box gridArea="randomData">
              {bacRandomChecks.length > 0 ? (
                <ScheduledRandomBacTable
                  bacRandomChecks={bacRandomChecks}
                  // bodyData={vcheckTableBodyData}
                ></ScheduledRandomBacTable>
              ) : (
                <Box background="light-3" pad="small" id="scheduled_null_message">
                  <Text weight="bold" size="small" alignSelf="center">
                    {intl.formatMessage(messages.randomBacCheckNullMessage)}
                  </Text>
                </Box>
              )}
            </Box>
            <Box gridArea="scheduledData">
              <Header mini level="6" margin={{ vertical: 'xsmall' }}>
                <FormattedMessage {...messages.scheduledBacCheckHeader} />
              </Header>

              {bacScheduledChecks.length > 0 ? (
                <ScheduledVCheckTable
                  vchecks={bacScheduledChecks}
                  // bodyData={vcheckTableBodyData}
                ></ScheduledVCheckTable>
              ) : (
                <Box background="light-3" pad="small" id="scheduled_null_message">
                  <Text weight="bold" size="small" alignSelf="center">
                    {intl.formatMessage(messages.scheduledBacCheckNullMessage)}
                  </Text>
                </Box>
              )}
            </Box>
          </Grid>
        </Section>
        <Section>
          <Grid
            rows={['auto', 'auto', 'auto']}
            columns={['auto', 'small']}
            gap="xsmall"
            areas={[
              ['verifyContent', 'verifyContent'],
              ['notes', 'notes'],
              ['activationCheckbox', 'activationCheckbox'],
            ]}
          >
            <Box gridArea="verifyContent">
              <Header mini level="5">
                <FormattedMessage {...messages.verifyInitiateHeader} />
              </Header>

              <Text size="small">
                <FormattedMessage {...messages.verifyInitiateDescriptionLineOne} />
              </Text>

              <Text size="small" margin={{ top: 'small' }}>
                <FormattedMessage {...messages.verifyInitiateDescriptionLineTwo} />
                <Text underline size="small">
                  {formatDate(basicInfo.monitoring_start)}
                </Text>
                .
              </Text>
            </Box>

            <Box gridArea="notes" margin={{ vertical: 'medium' }}>
              <TextArea
                id="notes"
                placeholder="Notes"
                value={notesState}
                onChange={e => setNotesState(e.target.value)}
              ></TextArea>
            </Box>

            <Box gridArea="activationCheckbox" gap="small">
              <Checkbox
                id="activation_checkbox"
                size="small"
                label={
                  <Text size="small" weight="bold">
                    {intl.formatMessage(messages.sendLinkCheckboxLabel)}
                  </Text>
                }
                checked={activationLinkChecked}
                onChange={e => setActivationLinkChecked(e.target.checked)}
              ></Checkbox>

              <Text size="small">
                <FormattedMessage {...messages.sendLinkDescription} />
                <Text underline size="small">
                  {clientPhone}
                </Text>
                .
                {/* <br />
                <Text size="samall">
                  <br />
                  <FormattedMessage {...messages.optInOrOptOutDescription} />
              </Text> */}
              </Text>
            </Box>
          </Grid>

          <Box>{error && <Message message={error} isError />}</Box>

          <Box direction="row-responsive" gap="small" justify="between">
            {/* Back */}
            <Button
              label={intl.formatMessage(messages.backButtonLabel)}
              primary={false}
              onClick={back}
              color="status-unknown"
            />

            {/* Verify and Close */}
            <Button
              color="accent-1"
              label={intl.formatMessage(messages.continueButtonLabel)}
              onClick={saveClient}
            />
          </Box>
        </Section>
      </Box>
    )
  },
)

export default injectIntl(VerifyClient)
