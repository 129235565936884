import React, { useEffect, useContext, useState, useReducer } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import { Add, CircleInformation } from 'grommet-icons'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { findIndex } from 'lodash'
import moment from 'moment'
import { Spinner } from 'grommet'
// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Header } from 'components/Header'
import { Message } from 'components/Message'
import { FormField } from 'components/Form'
import { Link } from 'components/Link'
import { ScheduledVCheckTable } from 'components/ScheduledVCheckTable'
import { Select } from 'components/Select'
import { Text } from 'components/Text'
import { TextBlock } from 'components/TextBlock'
import { TextInput } from 'components/TextInput'
import { VCheckScheduler } from 'components/VCheckScheduler'
import { WizardSidebar } from 'components/WizardSidebar'

// Stores
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils, Services & Messages
import messages from './AddClientBacSchedule.messages'
import rruleBuilder from '../../utils/rruleBuilder'
import useFlashMessage from '../../hooks/FlashMessage'

import colors from '../../utils/colors'
import randomRruleBuilders from '../../utils/randomRuleBuilder'
import { BacRandomScheduler } from '../../components/BacRandomScheduler'
import { ScheduledRandomBacTable } from '../../components/ScheduledRandomBacTable'

const AddClientBacSchedule = ({ intl, goToStep, clientIsVerified }) => {
  const {
    clientBasicInfo,
    distributorClientSelectedAgencyId,
    updateClientInAgency,
    getClientBacRandomById,
    getClientBacSchedulesById,
    postScheduledBacCheckToClient,
    updateScheduledBacCheckById,
    deleteScheduledBacCheckById,
    postRandomBacCheckToClient,
    updateRandomBacCheckById,
    deleteRandomBacCheckById,
  } = useContext(ClientWizardStoreContext)
  const { user, isDistributor } = useContext(UserStoreContext)

  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [pageLoadError, showPageLoadError] = useState(null)
  const [validationError, setValidationError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, seterrorMessage] = useState('  ')
  const [clientId, setClientId] = useState()
  // state of random bacCheck toggle and form fields
  const [showRandom, setShowRandom] = useState(false)
  const [enableBac, setEnableBac] = useState(
    clientBasicInfo ? clientBasicInfo.bac_schedule_enable || false : false,
  )

  const [bacCheckWindow, setBacCheckWindow] = useState(
    clientBasicInfo && clientBasicInfo.bac_checkin_window
      ? clientBasicInfo.bac_checkin_window.toString()
      : '60',
  )
  const [bacThreshold, setBacThreshold] = useState(
    clientBasicInfo && clientBasicInfo.bac_threshold ? clientBasicInfo.bac_threshold : '0.02',
  )

  // list of all scheduled bacChecks formatted to post to client
  const [scheduledBacChecks, setScheduledBacChecks] = useState()
  const [randomBacChecks, setRandomBacChecks] = useState()
  const [agencyId, setAgencyId] = useState(
    isDistributor ? distributorClientSelectedAgencyId : user.agencyId,
  )

  const randomBacCheckInitialState = {
    id: null,
    rStartTime: '08:00:00',
    rEndTime: '20:00:00',
    rFrequency: '1',
    weekdays: [],
  }

  const [randomState, updateRandomState] = useReducer(
    randomCheckReducer,
    randomBacCheckInitialState,
  )

  function randomCheckReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return randomBacCheckInitialState
  }

  useEffect(() => {
    async function setDefaultData() {
      // Retreive the client ID from URL parameters
      const urlParams = new URLSearchParams(window.location.search)
      const clientIdParam = urlParams.get('id')
      if (clientIdParam) {
        setClientId(clientIdParam)

        const bacchecks = await getClientBacSchedulesById(
          agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
        if (bacchecks !== null) {
          setScheduledBacChecks(bacchecks)
        }

        const randomBacChecks = await getClientBacRandomById(
          agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )

        // console.log('randomBacChecks', randomBacChecks)

        setRandomBacChecks(randomBacChecks)
      }
    }
    if (agencyId !== '') {
      setDefaultData()
    } else {
      setAgencyId(distributorClientSelectedAgencyId)
    }
  }, [agencyId])

  // boolean for showing sidebar
  const [showSidebar, setShowSidebar] = useState()

  // state of sidebar form for single bacCheck - cleared on "Save"
  const scheduledBacCheckInitialState = {
    name: '',
    startDate: moment(new Date())
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    startTime: '08:00',
    endTime: '08:15',
    repeat: 'Does Not Repeat',
    repeatEvery: '1',
    frequency: 'Week(s)',
    endsOn: 'Never',
    endDate: undefined,
    occurrences: '',
    weekdays: [],
  }

  const [scheduledState, dispatch] = useReducer(vcheckReducer, scheduledBacCheckInitialState)

  function vcheckReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return scheduledBacCheckInitialState
  }

  const updateClientDetails = async () => {
    const clientInfo = {
      bac_schedule_enable: enableBac,
      bac_checkin_window: Number(bacCheckWindow),
      bac_threshold: Number(bacThreshold),
      id: clientBasicInfo.id,
    }

    const response = await updateClientInAgency(agencyId, clientInfo, setLoading, showError)

    if (response) {
      return response
    }

    return null
  }

  const disableClientBac = async () => {
    const clientInfo = {
      id: clientBasicInfo.id,
      bac_schedule_enable: false,
    }

    const response = await updateClientInAgency(agencyId, clientInfo, setLoading, showError)

    if (response) {
      return response
    }

    return null
  }

  // Sidebar open/close functions
  const onClose = () => setShowSidebar(false)
  const openModal = () => {
    setShowSidebar(true)
  }

  const onCloseRandom = () => setShowRandom(false)
  const openRandomModal = () => {
    setShowRandom(true)
  }

  // Function to pass into ScheduledVCheckTable
  // that will open and populate sidebar for editing
  const editInSidebar = bacCheck => {
    dispatch({
      type: 'update',
      data: {
        id: bacCheck.id,
        exists: true,
        name: bacCheck.name,
        startDate: bacCheck.startDate,
        startTime: bacCheck.startTime,
        endTime: bacCheck.endTime,
        repeat: bacCheck.repeat || scheduledBacCheckInitialState.repeat,
        repeatEvery: bacCheck.repeatEvery || scheduledBacCheckInitialState.repeatEvery,
        frequency: bacCheck.frequency || scheduledBacCheckInitialState.frequency,
        endsOn: bacCheck.endsOn || scheduledBacCheckInitialState.endsOn,
        endDate: bacCheck.endDate,
        occurrences: bacCheck.occurrences || scheduledBacCheckInitialState.occurrences,
        weekdays: bacCheck.weekdays || scheduledBacCheckInitialState.weekdays,
      },
    })

    openModal()
  }

  const onChangetoggle = async e => {
    if (!e.target.checked) {
      disableClientBac()
    }
    setEnableBac(e.target.checked)
  }

  const editInRandomSidebar = bacCheck => {
    updateRandomState({
      type: 'update',
      data: {
        id: bacCheck.id,
        rStartTime: bacCheck.start,
        rEndTime: bacCheck.stop,
        rFrequency: bacCheck.frequency ? bacCheck.frequency.toString() : '1',
        weekdays: bacCheck.weekdays || randomBacCheckInitialState.weekdays,
      },
    })

    openRandomModal()
  }

  // Sidebar submit function
  const onSubmit = async () => {
    const reqestClientResponse = await updateClientDetails()
    if (reqestClientResponse) {
      let isExist = false
      let requestResponse
      if (scheduledState.id) {
        // update existing bacCheck.
        const updatedbacCheck = {
          id: scheduledState.id,
          name: scheduledState.name,
          schedule: rruleBuilder.rruleToString(scheduledState),
        }

        // Update the schedule in the API
        requestResponse = await updateScheduledBacCheckById(
          agencyId,
          clientId,
          updatedbacCheck,
          setLoading,
          showError,
        )

        if (requestResponse) {
          // Replace the current value of the schedule with the updated version in our store
          const index = findIndex(
            scheduledBacChecks,
            prevZone => prevZone.id === updatedbacCheck.id,
          )
          setScheduledBacChecks(
            Object.assign([], scheduledBacChecks, { [index]: updatedbacCheck }),
          )
        }
      } else {
        // add new bacCheck
        let newbacCheck = {}
        const errorMessagevalue =
          'BrAC Check already exists for the date & time, please choose another date & time'
        scheduledBacChecks.forEach(scheduledbacCheck => {
          const existingtime = `${scheduledbacCheck.schedule.slice(
            17,
            19,
          )}:${scheduledbacCheck.schedule.substring(19, 21)}`
          const existingDate = moment(scheduledbacCheck.schedule.slice(8, 16)).format('YYYY-MM-DD')
          if (
            existingDate === scheduledState.startDate &&
            existingtime === scheduledState.startTime
          ) {
            isExist = true
          }
        })
        if (!isExist) {
          newbacCheck = {
            name: scheduledState.name,
            schedule: rruleBuilder.rruleToString(scheduledState),
          }
        } else {
          return seterrorMessage(errorMessagevalue)
        }
        requestResponse = await postScheduledBacCheckToClient(
          agencyId,
          clientId,
          newbacCheck,
          setLoading,
          showError,
        )
        // Use the API response as the new bacCheck's data since it includes the ID
        if (requestResponse) {
          setScheduledBacChecks([...scheduledBacChecks, requestResponse])
        }
      }
      if (requestResponse) {
        // clear sidebar form state
        dispatch({ type: 'reset' })
        onClose()
      }
      return undefined
    }
    return seterrorMessage('Failed to create / update the schedule. Please try again ')
  }

  const onDelete = async () => {
    let requestSuccess = true

    // if it has been previously saved, send request to delete endpoint
    if (scheduledState.id) {
      requestSuccess = await deleteScheduledBacCheckById(
        agencyId,
        clientId,
        scheduledState.id,
        setLoading,
        showError,
      )
    }
    if (requestSuccess) {
      // remove from scheduledbacCheck list (use id if available, otherwise name)
      const filteredSchedules = scheduledBacChecks.filter(bacCheck =>
        bacCheck.id ? bacCheck.id !== scheduledState.id : bacCheck.name !== scheduledState.name,
      )
      setScheduledBacChecks(filteredSchedules)

      // clear sidebar form state
      dispatch({ type: 'reset' })
      onClose()
    }
  }

  const onSubmitRandom = async () => {
    const reqestClientResponse = await updateClientDetails()
    if (reqestClientResponse) {
      let isExist = false
      let requestResponse
      if (randomState.id) {
        // update existing bacCheck.
        const updatedbacCheck = {
          id: randomState.id,
          start: randomState.rStartTime,
          stop: randomState.rEndTime,
          frequency: randomState.rFrequency,
          schedule: randomRruleBuilders.rruleToString(randomState),
        }

        // Update the schedule in the API
        requestResponse = await updateRandomBacCheckById(
          agencyId,
          clientId,
          randomState.id,
          updatedbacCheck,
          setLoading,
          showError,
        )

        if (requestResponse) {
          // Replace the current value of the schedule with the updated version in our store
          const index = findIndex(randomBacChecks, prevZone => prevZone.id === updatedbacCheck.id)
          setRandomBacChecks(Object.assign([], randomBacChecks, { [index]: updatedbacCheck }))
        }
      } else {
        // add new bacCheck
        const newbacCheck = {
          start: moment(randomState.rStartTime, 'HH:mm:ss').format('HH:mm:ss'),
          stop: moment(randomState.rEndTime, 'HH:mm:ss').format('HH:mm:ss'),
          frequency: randomState.rFrequency,
          schedule: randomRruleBuilders.rruleToString(randomState),
        }
        // console.log
        const errorMessagevalue =
          'Bac Check already exists for the start & stop time, please choose another start & stop'
        isExist = randomBacChecks.some(
          randomBacCheck =>
            randomBacCheck.start === randomState.start && randomBacCheck.stop === randomState.stop,
        )
        if (isExist) {
          return seterrorMessage(errorMessagevalue)
        }
        requestResponse = await postRandomBacCheckToClient(
          agencyId,
          clientId,
          newbacCheck,
          setLoading,
          showError,
        )
        // Use the API response as the new bacCheck's data since it includes the ID
        if (requestResponse) {
          setRandomBacChecks([...randomBacChecks, requestResponse])
        }
      }
      if (requestResponse) {
        // clear sidebar form state
        updateRandomState({ type: 'reset' })
        onCloseRandom()
      }
      return undefined
    }
    return seterrorMessage('Failed to create / update the random schedule. Please try again ')
  }

  const onDeleteRandom = async () => {
    let requestSuccess = true

    // if it has been previously saved, send request to delete endpoint
    if (randomState.id) {
      requestSuccess = await deleteRandomBacCheckById(
        agencyId,
        clientId,
        randomState.id,
        setLoading,
        showError,
      )
    }
    if (requestSuccess) {
      // remove from scheduledbacCheck list (use id if available, otherwise name)
      const filteredSchedules = randomBacChecks.filter(bacCheck => bacCheck.id !== randomState.id)
      setRandomBacChecks(filteredSchedules)

      // clear sidebar form state
      updateRandomState({ type: 'reset' })
      onCloseRandom()
    }
  }

  const saveAndBack = () => {
    // const requestSuccess = saveRandomSchedule()
    // if (requestSuccess) {
    goToStep(3)
    // }
  }

  const saveScheduleAndContinue = async () => {
    const reqestClientResponse = await updateClientDetails()
    // const requestSuccess = await saveRandomSchedule()

    if (!clientIsVerified && reqestClientResponse) {
      goToStep(4)
    }
  }

  const verifyFuction = () => {
    setTimeout(() => {
      seterrorMessage(null)
    }, 1000)
  }

  const isFormValid =
    !!scheduledState.name && !!scheduledState.startDate && !!scheduledState.startTime

  // Build Sidebar header & content
  const sideBarHeader = scheduledState.exists ? (
    <FormattedMessage {...messages.scheduleSidebarHeaderEdit} />
  ) : (
    <FormattedMessage {...messages.scheduleSidebarHeaderAdd} />
  )
  const sidebarContent = (
    <Box>
      <Header mini level="5">
        <FormattedMessage {...messages.vcheckNameHeader} />
      </Header>

      <Box>
        <FormField
          component={TextInput}
          label={intl.formatMessage(messages.vcheckNameLabel)}
          name="bacCheck_name"
          id="bacCheck_name"
          onChange={e => dispatch({ fieldName: 'name', data: e.target.value })}
          value={{ value: scheduledState.name }}
          required
        />
      </Box>

      <Header mini level="5">
        <FormattedMessage {...messages.scheduleHeader} />
      </Header>

      <Text size="12px">
        <FormattedMessage {...messages.scheduleDetails} />
      </Text>

      <VCheckScheduler
        state={scheduledState}
        dispatch={dispatch}
        lowerBoundDate={moment(new Date()).format('YYYY-MM-DD')}
        messagecallback={verifyFuction}
      ></VCheckScheduler>

      <Box>{error && <Message message={error} isError />}</Box>
      {errorMessage !== null ? (
        <Text size="12px" color="red">
          {errorMessage}{' '}
        </Text>
      ) : (
        seterrorMessage('')
      )}
    </Box>
  )

  const isRandomFormValid =
    !!randomState.rFrequency && !!randomState.rStartTime && !!randomState.rEndTime

  // Build Sidebar header & content
  const randomSideBarHeader = randomState.id ? (
    <FormattedMessage {...messages.randomSidebarHeaderEdit} />
  ) : (
    <FormattedMessage {...messages.randomSidebarHeaderAdd} />
  )
  const randomSidebarContent = (
    <Box>
      <Header mini level="5">
        <FormattedMessage {...messages.vcheckNameHeader} />
      </Header>

      <BacRandomScheduler
        state={randomState}
        updateRandomState={updateRandomState}
        lowerBoundDate={moment(new Date()).format('YYYY-MM-DD')}
        validationError={error => {
          setValidationError(error)
        }}
      ></BacRandomScheduler>

      <Box>{error && <Message message={error} isError />}</Box>
      {errorMessage !== null ? (
        <Text size="12px" color="red">
          {errorMessage}{' '}
        </Text>
      ) : (
        seterrorMessage('')
      )}
    </Box>
  )

  if (pageLoadError) {
    return (
      <Box fill justify="center" align="center">
        <Message message={pageLoadError} isError />
      </Box>
    )
  }

  return (
    <Box margin={{ bottom: 'large' }} width={{ max: '900px' }}>
      <Box direction="row" justify="between" width={{ max: '350px' }}>
        <Header mini level="5">
          <FormattedMessage {...messages.enableBacSchedule} />
        </Header>

        <Checkbox
          id="enable_bac_toggle"
          toggle
          checked={enableBac}
          onChange={e => onChangetoggle(e)}
        ></Checkbox>
      </Box>
      {enableBac && (
        <>
          <Box direction="row" justify="between" width={{ max: '900px' }}>
            <Header mini level="5">
              <FormattedMessage {...messages.bacCheckInWindow} />
            </Header>

            <FormField name="bac_check_in_wondown" id="bac_check_in_wondown" label="MINUTES">
              <Select
                plain
                size="small"
                name="bac_check_in_wondown"
                id="bac_check_in_wondown"
                options={['60', '45', '30', '15']}
                value={bacCheckWindow}
                onChange={event => {
                  setBacCheckWindow(event.target.value)
                }}
                style={{ maxWidth: '50px' }}
              ></Select>
            </FormField>

            <Box align="center" direction="row" gap="small" width="medium">
              <CircleInformation color={colors.mediumGrey} size="16px" />
              <Text color={colors.mediumGrey} size="16px">
                <FormattedMessage {...messages.bacCheckMinutesDescription} />
              </Text>
            </Box>
          </Box>
          <Box direction="row" justify="between" width={{ max: '900px' }}>
            <Header mini level="5">
              <FormattedMessage {...messages.bacCheckThreshold} />
            </Header>

            <Box direction="row-responsive" gap="small" margin={{ top: 'small' }}>
              <FormField name="bac_threshold" id="bac_threshold" required disableAstreik>
                <TextInput
                  type="number"
                  step="0.01" // Ensures only 2 decimal places
                  min="0"
                  max="0.09"
                  value={bacThreshold}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value <= 0.09) {
                      setBacThreshold(value)
                    }
                  }}
                />
              </FormField>
            </Box>

            <Box align="center" direction="row" gap="small" width="medium">
              <CircleInformation color={colors.mediumGrey} size="16px" />
              <Text color={colors.mediumGrey} size="16px">
                <FormattedMessage {...messages.bacCheckWeekdayDescription} />
              </Text>
            </Box>
          </Box>
          <Box direction="row" justify="between">
            <Header mini level="5">
              <FormattedMessage {...messages.bacRandomCheckHeader} />
            </Header>

            {/* <Checkbox
              id="random_toggle"
              toggle
              checked={showRandom}
              onChange={e => setShowRandom(e.target.checked)}
            ></Checkbox> */}
            <Button
              color="focus"
              icon={<Add size="small" />}
              label={intl.formatMessage(messages.bacAddRandomButtonLabel)}
              onClick={() => {
                updateRandomState({ type: 'reset' })
                openRandomModal()
              }}
            />
          </Box>

          <TextBlock
            multiLineString={messages.bacRandomCheckDescription.defaultMessage}
            fontSize="xsmall"
          ></TextBlock>

          {randomBacChecks && randomBacChecks.length > 0 && (
            <ScheduledRandomBacTable
              editable
              bacRandomChecks={randomBacChecks}
              editInSidebar={editInRandomSidebar}
            />
          )}

          {loading && (
            <Spinner color="focus" size="medium" margin={{ left: 'xlarge', top: 'medium' }} />
          )}

          <Divider />

          <Header mini level="5" margin={{ top: 'large', bottom: '0px' }}>
            <FormattedMessage {...messages.bacScheduledCheckHeader} />
          </Header>

          <Box direction="row-responsive" alignSelf="end">
            <Button
              color="focus"
              icon={<Add size="small" />}
              label={intl.formatMessage(messages.bacAddScheduleButtonLabel)}
              onClick={() => {
                dispatch({ type: 'reset' })
                openModal()
              }}
            />
          </Box>

          {scheduledBacChecks && scheduledBacChecks.length > 0 && (
            <ScheduledVCheckTable
              editable
              vchecks={scheduledBacChecks}
              editInSidebar={editInSidebar}
            />
          )}

          {loading && (
            <Spinner color="focus" size="medium" margin={{ left: 'xlarge', top: 'medium' }} />
          )}
        </>
      )}
      <Box>{error && <Message message={error} isError />}</Box>

      <Box direction="row-responsive" gap="small" justify="between">
        {/* Back */}
        <Button
          label={intl.formatMessage(messages.backButtonLabel)}
          primary={false}
          onClick={saveAndBack}
          color="status-unknown"
        />

        {/* Save & Continue */}
        {/* directs client to Verify tab or Summary Page based on verified status */}
        {!clientIsVerified ? (
          <Button
            color="accent-1"
            label={intl.formatMessage(messages.continueButtonLabel)}
            onClick={saveScheduleAndContinue}
            clientIsVerified={clientIsVerified}
            disabled={validationError || loading}
          />
        ) : (
          <Link to={`/clients/summary?id=${clientId}`}>
            <Button
              color="accent-1"
              label={intl.formatMessage(messages.closeButtonLabel)}
              onClick={saveScheduleAndContinue}
              clientIsVerified={clientIsVerified}
              disabled={loading}
            />
          </Link>
        )}
      </Box>

      {showRandom && (
        <WizardSidebar
          disabled={loading}
          onClose={onCloseRandom}
          onSubmit={onSubmitRandom}
          onDelete={onDeleteRandom}
          isFormValid={isRandomFormValid}
          isAbleToDelete={!!randomState.id}
          header={randomSideBarHeader}
          content={randomSidebarContent}
        ></WizardSidebar>
      )}
      {showSidebar && (
        <WizardSidebar
          disabled={loading}
          onClose={onClose}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isFormValid={isFormValid}
          isAbleToDelete={!!scheduledState.exists}
          header={sideBarHeader}
          content={sidebarContent}
        ></WizardSidebar>
      )}
    </Box>
  )
}

const Divider = styled.div`
  margin-top: 20px;
  border: 0.5px solid lightGrey;
  width: 100%;
`

AddClientBacSchedule.propTypes = {
  intl: intlShape.isRequired,
  goToStep: PropTypes.func.isRequired,
  clientIsVerified: PropTypes.bool,
}

export default injectIntl(AddClientBacSchedule)
